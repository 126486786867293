<template>
	<div>
		<h1><i class="fa-regular fa-sign-out-alt"></i> Logout</h1>

		<div class="text-center" v-show="!done">Logging Out...</div>
		<div class="text-center" v-show="done">You have been logged out. <router-link to="/login">Login Again</router-link></div>
	</div>
</template>

<script>
export default
{
	name: 'Home',
	data()
	{
		return {
			done: false
		}
	},
	mounted()
	{
		this.logout();
	},
	methods:
	{
		logout()
		{
			this.CORS("GET", "/logout", null,
			() =>
			{
				this.done = true;
				this.$emit("logout");
				setTimeout(() =>
				{
					this.$router.push("/login");
				}, 5000);
			},
			(response) =>
			{
				this.showError("Error Logging Out", response.responseText, true, null);
			})
		}
	}
}
</script>
